<template>
  <div>
    <div class="vasion-card vld-parent">
      <Loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <div v-show="!isLoading" class="card-settings-area">
        <h2>Feature Flags</h2>
        <VasionCheckbox
          id="advanced-pdf-form-generation-enabled"
          name="advanced-pdf-form-generation-enabled"
          :checked="advancedPdfFormGenerationEnabled"
          @change="saveAdvancedPdfFormGenerationValue"
        >
          Advanced PDF Form Generation
        </VasionCheckbox>
        <VasionCheckbox
          id="default-encryption-enabled"
          ref="encryptionCheckbox"
          name="default-encryption-enabled"
          :checked="defaultEncryptionEnabled"
          @value="defaultEncryptionToggled"
        >
          Default Encryption
        </VasionCheckbox>
        <VasionCheckbox
          id="parallel-routing-enabled"
          name="parallel-routing-enabled"
          :checked="parallelRoutingEnabled"
          @change="saveNewParallelRoutingValue"
        >
          Workflow Parallel Routing Enabled
        </VasionCheckbox>
        <VasionCheckbox
          id="signature-certificates"
          name="signature-certificates"
          :checked="signatureCertificates"
          @change="saveNewSignatureCertificatesValue"
        >
          Signature Certificates
        </VasionCheckbox>
        <VasionCheckbox
          id="view-access-all-workflow-tasks"
          name="view-access-all-workflow-tasks"
          :checked="openAllWFDocs"
          @change="saveNewOpenAllWFDocs"
        >
          Allow View access to all Workflow Tasks via Search
        </VasionCheckbox>
      </div>
    </div>
    <VasionGeneralModal
      id="prompt-password"
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'OK'"
      :modalType="'slot'"
      :message="'Are you sure'"
      :sync="showPromptPassword"
      @noButtonClick="passwordPromptClosed"
      @confirmButtonClick="saveDefaultEncryptionValue"
    >
      <div class="prompt-password-dialog">
        <div class="password-label">
          Please enter the Default encryption password
        </div>
        <div class="entered-password">
          <VasionInput
            v-model="enteredPassword"
            class="input-style"
            inputType="top-white"
            placeholder="Enter Password..."
            type="password"
            autocomplete="off"
          />
        </div>
      </div>
    </VasionGeneralModal>
    <VasionSnackbar
      id="vasion-snackbar"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'FeatureFlags',
  components: {
    Loading,
  },
  data: function () {
    return {
      advancedPdfFormGenerationEnabled: false,
      defaultEncryptionEnabled: false,
      enteredPassword: '',
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      openAllWFDocs: false,
      parallelRoutingEnabled: false,
      showPromptPassword: false,
      showSnackbar: false,
      signatureCertificates: false,
      snackbarImage: true,
      snackbarSubTitle: 'Attributes Saved!',
      snackbarTitle: '',
      randomData: false,
    }
  },
  watch: {
    randomData() {
      if (this.randomData) {
        this.defaultEncryptionEnabled = true
        console.log('random', this.defaultEncryptionEnabled)
      }
    },
  },
  created: async function () {
    this.isLoading = true

    const promiseResults = await Promise.all([
      this.$store.dispatch('common/getConfig', 'AdvancedPDFForm'),
      this.$store.dispatch('common/getConfig', 'EnableWFParallelRoute'),
      this.$store.dispatch('common/getConfig', 'GlobalFolderEncryption'),
      this.$store.dispatch('common/getConfig', 'SignatureCertificates'),
      this.$store.dispatch('common/getConfig', 'OpenAllWFDocs'),
    ])

    this.advancedPdfFormGenerationEnabled = promiseResults[0].toLowerCase() === 'true'
    this.parallelRoutingEnabled = promiseResults[1].toLowerCase() === 'true'
    this.defaultEncryptionEnabled = promiseResults[2].toLowerCase() === 'true'
    this.signatureCertificates = promiseResults[3].toLowerCase() === 'true'
    this.openAllWFDocs = promiseResults[4].toLowerCase() === 'true'

    this.isLoading = false
  },
  methods: {
    /**
     * Called when the default encryption checkbox is toggled.
     */
    defaultEncryptionToggled(checked) {
      this.defaultEncryptionEnabled = checked
      if (checked) {
        this.saveDefaultEncryptionValue()
      } else {
        this.showPromptPassword = true
      }
    },
    passwordPromptClosed() {
      this.enteredPassword = ''
      this.showPromptPassword = false
      this.defaultEncryptionEnabled = true
    },
    saveAdvancedPdfFormGenerationValue() {
      this.advancedPdfFormGenerationEnabled = !this.advancedPdfFormGenerationEnabled
      const config = {
        sConfigName: 'AdvancedPDFForm',
        sConfigValue: this.advancedPdfFormGenerationEnabled,
      }
      this.$store.dispatch('common/setConfig', config)
    },
    async saveDefaultEncryptionValue() {
      const result = await this.$store.dispatch('vault/setClientDefaultEncryptionEnabled', {
          enabled: this.defaultEncryptionEnabled,
          currentPassword: this.enteredPassword
        })

        if (result.Value.toLowerCase() === 'false') {
          this.showSnackbar = true
          this.snackbarTitle = 'Error'
          this.snackbarSubTitle = 'There was an error setting the default encryption.'
          this.snackbarImage = false
          return
        }
        this.showPromptPassword = false
        this.enteredPassword = ''
    },
    saveNewOpenAllWFDocs() {
      this.openAllWFDocs = !this.openAllWFDocs
      const config = {
        sConfigName: 'OpenAllWFDocs',
        sConfigValue: this.openAllWFDocs,
      }
      this.$store.dispatch('common/setConfig', config)
    },
    saveNewParallelRoutingValue() {
      this.parallelRoutingEnabled = !this.parallelRoutingEnabled
      const config = {
        sConfigName: 'EnableWFParallelRoute',
        sConfigValue: this.parallelRoutingEnabled,
      }
      this.$store.dispatch('common/setConfig', config)
    },
    saveNewSignatureCertificatesValue() {
      this.signatureCertificates = !this.signatureCertificates
      const config = {
        sConfigName: 'SignatureCertificates',
        sConfigValue: this.signatureCertificates,
      }
      this.$store.dispatch('common/setConfig', config)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
  .vasion-card {
    width: 1000px;
    min-height: 422px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(22, 23, 24, 0.15);
    z-index: 1;
    margin: 0 auto;
    margin-top: 74px;
    background-color: $white;
    display: flex;
    padding: 40px;
    flex-direction: column;
  }
</style>
